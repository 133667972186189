'use client';

import type { GraphQLErrors } from '@apollo/client/errors';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import { FanExtensionErrorCode } from '@/__generated__/graphql';
import { Header } from '@/components/layout/header';
import { PageWrapper } from '@/components/layout/page/PageWrapper';
import { Button } from '@/components/ui/button/Button';
import { Loading } from '@/components/ui/loading/Loading';
import { PATHS } from '@/constants/path';
import { useRouter } from '@/i18n/routing';
import { useAuthenticationContext } from '@/providers/auth-provider';

export default function Error({
  error,
}: {
  error: Error & { digest?: string; graphQLErrors?: GraphQLErrors };
}) {
  const { push } = useRouter();
  const t = useTranslations();

  if (error.graphQLErrors?.[0]) {
    const errorCode = error.graphQLErrors[0].extensions.code as keyof typeof FanExtensionErrorCode;
    if (errorCode === FanExtensionErrorCode.Unauthorized) {
      return <ViewLogin />;
    }
  }

  return (
    <PageWrapper bgColor='white' className='flex flex-col items-center'>
      <Header logo={true} desktopHeaderHidden={true} />
      <div className='whitespace-pre pb-[8.5rem] pt-[10rem]'>
        {t('common_error_generic_description_three')}
      </div>
      <div className='w-full px-2.5x'>
        <Button label={t('common-back_to_top')} fullWidth onClick={() => push(PATHS.ROOT)} />
      </div>
    </PageWrapper>
  );
}

function ViewLogin() {
  const { login } = useAuthenticationContext();
  const { push } = useRouter();

  useEffect(() => {
    (async () => {
      try {
        await login();
      } catch (error) {
        console.log(error);
      } finally {
        push(PATHS.ROOT);
      }
    })();
  }, []);

  return (
    <PageWrapper bgColor='white'>
      <Loading />
    </PageWrapper>
  );
}
